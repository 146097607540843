<template>
  <div class="main">
    <nav-header></nav-header>
    <el-main class="home">
      <el-row type="flex" justify="center">
        <el-col :span="3"></el-col>
        <el-col :span="18" class="home-top">
          <el-row type="flex" justify="start" class="home-top-title-row">
            <el-col class="home-top-title">智能项目申报</el-col>
          </el-row>
          <el-row type="flex" justify="start">
            <el-col class="home-top-slogan">为企业提供更好更便捷的服务</el-col>
          </el-row>
          <el-row type="flex" justify="start" class="home-top-keyword-row">
            <el-col :span="2" class="home-top-keyword">
              <img src="../assets/image/version1/icon-zhuanzhu.png" alt="">
              <span>专注</span>
            </el-col>
            <el-col :span="2" class="home-top-keyword">
              <img src="../assets/image/version1/icon-zhuanye.png" alt="">
              <span>专业</span>
            </el-col>
            <el-col :span="2" class="home-top-keyword">
              <img src="../assets/image/version1/icon-fuwu.png" alt="">
              <span>服务</span>
            </el-col>
            <el-col :span="2" class="home-top-keyword">
              <img src="../assets/image/version1/icon-pinpai.png" alt="">
              <span>品牌</span>
            </el-col>
          </el-row>
          <el-row type="flex" justify="start" class="home-top-button-row">
            <el-col class="home-top-button">
              <el-button round @click="openLeaveContact">马上了解</el-button>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="3"></el-col>
      </el-row>
      <el-row class="home-second">
        <el-row>
          <el-col :span="24" class="home-second-bg"></el-col>
        </el-row>
        <el-row type="flex" justify="start" class="home-second-title">
          <el-col :span="3"></el-col>
          <el-col :span="21" style="align-items: center; display: flex;">
            <span class="home-little-title">热门政策</span><img src="../assets/image/version1/home-second-icon.png"
                                                                alt="" class="home-little-icon">
          </el-col>
        </el-row>
        <el-row type="flex" justify="start" class="home-second-content">
          <el-col :span="3"></el-col>
          <el-col :span="18" style="align-items: center; display: flex;">
            <el-collapse v-model="activeName" accordion>
              <el-collapse-item v-for="(item, i) in hotPolicies" :key="i" :name="String(i)" :title="item.title">
                <template #title>
                  <div class="el-collapse-item-title">{{ item.title }}</div>
                </template>
                <p>{{ item.content }}</p>
                <div style="display: flex;justify-content: space-between;align-items: center;padding-right: 15px;">
                  <span>发布时间：{{ item.publishTime }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;来源：{{ item.source }}</span>
                  <a :href="item.url" target="_blank" style="cursor:pointer;color: #006Db8;">查看详情</a>
                </div>
              </el-collapse-item>
              <div class="home-second-content-button">
                <el-button round >
                  <router-link style="color: #ffffff" to="/allZc">查看全部</router-link>
                </el-button>
              </div>
            </el-collapse>
          </el-col>
          <el-col :span="3"></el-col>
        </el-row>
      </el-row>
      <el-row class="home-third">
        <el-row type="flex" justify="start" class="home-third-title">
          <el-col :span="3"></el-col>
          <el-col :span="21" style="align-items: center; display: flex;">
            <span class="home-little-title">服务流程</span><img src="../assets/image/version1/home-second-icon.png"
                                                                alt="" class="home-little-icon">
          </el-col>
        </el-row>
        <el-row type="flex" justify="start" class="home-third-content">
          <el-col :span="3"></el-col>
          <el-col :span="6">
            <el-row type="flex" justify="start" v-for="(item, i) in serviceProcessLeft" :key="i"
                    class="home-third-content-row">
              <el-col :span="6" style="display: flex; justify-content: center; align-items: center;">
                <img class="home-third-content-no" :src="item.imageName" alt="">
              </el-col>
              <el-col :span="18" style="display: flex; justify-content: center; align-items: center;">
                <div>
                  <div class="home-third-content-title">{{ item.title }}</div>
                  <span class="home-third-content-keyword">{{ item.keyword }}</span>
                  <img class="home-third-content-arrow" src="../assets/image/version1/home-third-content-arrow.png"
                       alt="">
                </div>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="6">
            <img class="home-third-content-m" src="../assets/image/version1/home-third-content-m.png" alt="">
          </el-col>
          <el-col :span="6">
            <el-row type="flex" justify="start" v-for="(item, i) in serviceProcessRight" :key="i"
                    class="home-third-content-row-right">
              <el-col :span="18" style="display: flex; justify-content: center; align-items: center;">
                <div style="text-align: right;">
                  <div class="home-third-content-title">{{ item.title }}</div>
                  <img class="home-third-content-arrow-right"
                       src="../assets/image/version1/home-third-content-arrow.png" alt="">
                  <span class="home-third-content-keyword">{{ item.keyword }}</span>
                </div>
              </el-col>
              <el-col :span="6" style="display: flex; justify-content: center; align-items: center;">
                <img class="home-third-content-no" :src="item.imageName" alt="">
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="3"></el-col>
        </el-row>
      </el-row>
      <el-row class="home-fourth">
        <el-row type="flex" justify="start" class="home-fourth-title">
          <el-col :span="3"></el-col>
          <el-col :span="18" style="align-items: center; display: flex; justify-content: space-between;">
            <div style="align-items: center; display: flex;">
              <span class="home-little-title">初创期企业</span>
              <img src="../assets/image/version1/home-second-icon.png" alt="" class="home-little-icon">
            </div>
            <div class="home-look-all-button">
              <el-button>查看全部<img src="../assets/image/version1/look-all-button-icon.png" alt=""
                                      class="home-button-icon"></el-button>
            </div>
          </el-col>
          <el-col :span="3"></el-col>
        </el-row>
        <el-row type="flex" justify="start" class="home-fourth-content">
          <el-col :span="3"></el-col>
          <el-col :span="18">
            <el-table :data="startUpPeriodData" stripe :header-row-class-name="homeTableHeaderRowClassName"
                      :header-cell-class-name="homeTableHeaderCellClassName">
              <el-table-column prop="projectName" label="项目名称" min-width="15%"></el-table-column>
              <el-table-column prop="department" label="归口单位" min-width="10%"></el-table-column>
              <el-table-column prop="reportDate" label="申报时间" min-width="10%"></el-table-column>
              <el-table-column prop="requirement" label="基本要求" min-width="40%"></el-table-column>
              <el-table-column prop="policyBonus" label="政策红利" min-width="25%"></el-table-column>
            </el-table>
          </el-col>
          <el-col :span="3"></el-col>
        </el-row>
      </el-row>
      <el-row class="home-fifth">
        <el-row type="flex" justify="start" class="home-fifth-title">
          <el-col :span="3"></el-col>
          <el-col :span="18" style="align-items: center; display: flex; justify-content: space-between;">
            <div style="align-items: center; display: flex;">
              <span class="home-little-title">成长期企业</span>
              <img src="../assets/image/version1/home-second-icon.png" alt="" class="home-little-icon">
            </div>
            <div class="home-look-all-button">
              <el-button>查看全部<img src="../assets/image/version1/look-all-button-icon.png" alt=""
                                      class="home-button-icon"></el-button>
            </div>
          </el-col>
          <el-col :span="3"></el-col>
        </el-row>
        <el-row type="flex" justify="start" class="home-fifth-content">
          <el-col :span="3"></el-col>
          <el-col :span="18">
            <el-table :data="growthPeriodData" stripe :header-row-class-name="homeTableHeaderRowClassName"
                      :header-cell-class-name="homeTableHeaderCellClassName">
              <el-table-column prop="projectName" label="项目名称" min-width="15%"></el-table-column>
              <el-table-column prop="department" label="归口单位" min-width="10%"></el-table-column>
              <el-table-column prop="reportDate" label="申报时间" min-width="10%"></el-table-column>
              <el-table-column prop="requirement" label="基本要求" min-width="40%"></el-table-column>
              <el-table-column prop="policyBonus" label="政策红利" min-width="25%"></el-table-column>
            </el-table>
          </el-col>
          <el-col :span="3"></el-col>
        </el-row>
      </el-row>
      <el-row class="home-sixth">
        <el-row type="flex" justify="start" class="home-sixth-title">
          <el-col :span="3"></el-col>
          <el-col :span="18" style="align-items: center; display: flex; justify-content: space-between;">
            <div style="align-items: center; display: flex;">
              <span class="home-little-title">成熟期企业</span>
              <img src="../assets/image/version1/home-second-icon.png" alt="" class="home-little-icon">
            </div>
            <div class="home-look-all-button">
              <el-button>查看全部<img src="../assets/image/version1/look-all-button-icon.png" alt=""
                                      class="home-button-icon"></el-button>
            </div>
          </el-col>
          <el-col :span="3"></el-col>
        </el-row>
        <el-row type="flex" justify="start" class="home-sixth-content">
          <el-col :span="3"></el-col>
          <el-col :span="18">
            <el-table :data="maturePeriodData" stripe :header-row-class-name="homeTableHeaderRowClassName"
                      :header-cell-class-name="homeTableHeaderCellClassName">
              <el-table-column prop="projectName" label="项目名称" min-width="15%"></el-table-column>
              <el-table-column prop="department" label="归口单位" min-width="10%"></el-table-column>
              <el-table-column prop="reportDate" label="申报时间" min-width="10%"></el-table-column>
              <el-table-column prop="requirement" label="基本要求" min-width="40%"></el-table-column>
              <el-table-column prop="policyBonus" label="政策红利" min-width="25%"></el-table-column>
            </el-table>
          </el-col>
          <el-col :span="3"></el-col>
        </el-row>
      </el-row>

    </el-main>
    <nav-footer></nav-footer>
  </div>
</template>

<script>
import NavHeader from './../components/navHeader'
import NavFooter from './../components/navFooter'

export default {
  name: "home",
  data() {
    return {
      activeName: '0',
      hotPolicies: [
        {
          titleIcon: require('../assets/image/version1/zc-second-list-example1.png'),
          title: "关于2025年度河南省创新型中小企业 拟公告名单的公示",
          content: "根据《河南省优质中小企业梯度培育管理实施细则（暂行）》（豫工信企业〔2023〕45号）、《河南省工业和信息化厅办公室关于开展2025年河南省创新型中小企业评价工作的通知》（豫工信办企业〔2025〕25号），经企业自愿申报，各省辖市、济源示范区、航空港区工业和信息化主管部门审核、实地核查和公示等程序，现将拟入选2025年度河南省创新型中小企业名单予以公示。",
          source: "厅中小企业局",
          publishTime: "2025-03-31 16:37",
          url: "https://gxt.henan.gov.cn/2025/03-31/3143111.html"
        },
        {
          titleIcon: require('../assets/image/version1/zc-second-list-example1.png'),
          title: "河南省工业和信息化厅 河南省生态环境厅 河南省水利厅 关于遴选2025年工业废水循环利用标杆企业和 园区的通知",
          content: "各省辖市、济源示范区、航空港区工业和信息化、生态环境和水行政主管部门：按照《关于推进污水资源化利用的指导意见》《工业废水循环利用实施方案》《关于加快发展节水产业的实施意见》有关要求，为推进工业废水循环利用，提升工业水资源集约节约利用水平，现组织开展2025年工业废水循环利用标杆企业和园区遴选工作，有关事项通知如下：",
          source: "河南省工业和信息化厅",
          publishTime: "2025-03-24 16:13",
          url: "https://gxt.henan.gov.cn/2025/03-24/3140466.html"
        },
        {
          titleIcon: require('../assets/image/version1/zc-second-list-example1.png'),
          title: "河南省工业和信息化厅办公室 关于开展2025年度第一批河南省专精特新 中小企业培育工作的通知",
          content: "各省辖市、济源示范区、航空港区工业和信息化主管部门：\n" +
              "\n" +
              "　　根据《河南省优质中小企业梯度培育管理实施细则（暂行）》（豫工信企业〔2023〕45号，以下简称《实施细则》），现组织开展2025年度第一批河南省专精特新中小企业培育工作。有关事项通知如下。",
          source: "河南省工业和信息化厅",
          publishTime: "2025-03-25 09:39",
          url: "https://gxt.henan.gov.cn/2025/03-25/3140689.html"
        },
        {
          titleIcon: require('../assets/image/version1/zc-second-list-example1.png'),
          title: "关于印发《郑州市高新技术企业认定奖补实施细则》的通知",
          content: "各有关开发区、区县（市）科技主管部门、财政局，各有关单位：现将《郑州市高新技术企业认定奖补实施细则》印发给你们，请遵照执行。",
          source: "郑州市科学技术局高新处",
          publishTime: "2025-03-17 15:32:18",
          url: "https://zzkj.zhengzhou.gov.cn/tzgg/9154509.jhtml"
        },
        {
          titleIcon: require('../assets/image/version1/zc-second-list-example1.png'),
          title: "关于开展2025年度郑州市科技型企业评价工作的通知",
          content: "各开发区、区县（市）科技主管部门，各有关单位：\n" +
              "\n" +
              "根据《郑州市科技型企业评价管理办法》（郑科规〔2020〕2号）（以下简称《评价办法》）有关要求，市科技局决定组织开展2025年度郑州市科技型企业评价工作，现将有关事宜通知如下：",
          source: "郑州市科学技术局高新处",
          publishTime: "2025-03-11 10:35:00 ",
          url: "https://zzkj.zhengzhou.gov.cn/tzgg/9138416.jhtml"
        },
        {
          titleIcon: require('../assets/image/version1/zc-second-list-example1.png'),
          title: "关于组织申报2025年市级企业技术中心的通知",
          content: "各开发区、区县（市）工信部门，有关企业：\n" +
              "\n" +
              "为加快培育创新平台，促进企业技术创新，市工信局决定开展2025年郑州市企业技术中心申报工作。现将有关事项通知如下：",
          source: "郑州市工业和信息化局",
          publishTime: "2025-03-05 14:27",
          url: "https://gxj.zhengzhou.gov.cn/tzgg/9113828.jhtml"
        },
/*        {
          titleIcon: require('../assets/image/version1/zc-second-list-example1.png'),
          title: "关于郑州市加快生物医药产业发展专项财政奖励拟推荐项目的公示",
          content: "根据《郑州市人民政府办公厅关于加快生物医药产业发展的实施意见》（郑政办〔2022〕52号）、《郑州市工业和信息化局  郑州市财政局关于印发郑州市加快生物医药产业发展若干政策实施细则的通知》（郑工信食品〔2024〕1号）和《郑州市工业和信息化局 郑州市财政局关于组织开展2022年度和2023年度郑州市加快生物医药产业发展专项资金申报工作的通知》（郑工信食品〔2024〕2号）要求",
          source: "郑州市工业和信息化局",
          publishTime: "2025-03-03 10:30",
          url: "https://gxj.zhengzhou.gov.cn/tzgg/9113828.jhtml"
        },*/
/*        {
          titleIcon: require('../assets/image/version1/zc-second-list-example1.png'),
          title: "河南省工业和信息化厅 河南省财政厅 关于做好2025年第一季度规上工业企业满负荷 生产财政奖励资金申报工作的通知",
          content: "各省辖市、济源示范区、航空港区工业和信息化主管部门、财政部门，各县（市）工业和信息化主管部门、财政局：\n" +
              "\n" +
              "　　为落实《河南省人民政府办公厅关于印发推动2025年第一季度经济“开门红”若干政策措施的通知》（豫政办明电〔2025〕1号）要求，巩固和增强经济回升向好态势，推动第一季度全省经济实现“开门红”，现就2025年第一季度规上工业企业满负荷生产财政奖励资金申报事项通知如下：",
          source: "河南省厅运行监测协调局",
          publishTime: "2025-02-21 14:55",
          url: "https://gxt.henan.gov.cn/2025/02-21/3126339.html"
        },*/
/*        {
          titleIcon: require('../assets/image/version1/zc-second-list-example1.png'),
          title: "关于印发《郑州市绿色制造体系梯度培育及 管理暂行办法》的通知",
          content: "各开发区、区县（市）工信部门，各有关单位：\n" +
              "\n" +
              "现将《郑州市绿色制造体系梯度培育及管理暂行办法》印发给你们，请认真贯彻实施。",
          source: "郑州市工业和信息化局",
          publishTime: "2025年1月15日",
          url: "https://gxj.zhengzhou.gov.cn/zcfg/9006419.jhtml"
        },
        {
          titleIcon: require('../assets/image/version1/zc-second-list-example1.png'),
          title: "关于《郑州市高新技术企业认定奖补实施细则（征求意见稿）》向社会公开征求意见的公告",
          content: "为规范开展郑州市高新技术企业认定奖补工作，根据《郑州市高新技术企业培育三年倍增计划实施方案 (2024—2026年)》（郑政办〔2024〕51号），郑州市科技局、市财政局联合研究制定了《郑州市高新技术企业认定奖补实施细则》（征求意见稿）。现面向社会公开征求意见，请社会各界提出意见建议。",
          source: "高新处",
          publishTime: "2025-01-13 16:26 ",
          url: "https://zzkj.zhengzhou.gov.cn/tzgg/8999601.jhtml"
        },*/
/*        {
          titleIcon: require('../assets/image/version1/zc-second-list-example1.png'),
          title: "河南省科学技术厅 河南省财政厅 关于组织申报2025年度河南省科技基础条件专项资金项目的通知",
          content: "各省级科研单位：根据《河南省省级创新生态支撑专项资金管理办法》（豫财科〔2023〕1号）有关要求，按照我省打造一流创新生态的总体部署和年度预算安排，现组织开展2025年度省科技基础条件专项资金项目申报工作，具体事项通知如下：",
          source: "科技项目统筹推进处",
          publishTime: "2025-01-14 15:50",
          url: "https://wap.kjt.henan.gov.cn/2025/01-14/3111998.html"
        },*/
/*        {
          titleIcon: require('../assets/image/version1/zc-second-list-example1.png'),
          title: "河南省人民政府\n" +
              "关于印发加快服务业高质量发展若干政策措施的通知",
          content: "各省辖市人民政府，济源示范区、航空港区管委会，省人民政府各部门：\n" +
              "\n" +
              "　　现将《加快服务业高质量发展若干政策措施》印发给你们，请认真贯彻执行。",
          source: "河南省人民政府",
          publishTime: "2024年12月19日",
          url: "https://m.henan.gov.cn/2024/12-30/3105783.html"
        },*/
/*        {
          titleIcon: require('../assets/image/version1/zc-second-list-example1.png'),
          title: "2025年度河南省科技攻关拟立项项目公示清单",
          content: "2025年度河南省科技攻关拟立项项目公示清单",
          source: "河南省政府",
          publishTime: "2024-12-30 17:54",
          url: "./#/detail?id=6"
        },*/
      ],
      serviceProcessLeft: [
        {
          imageName: require("../assets/image/version1/home-third-content-1.png"),
          title: "确认需求",
          keyword: "需求梳理 签订合同 紧急处理"
        },
        {
          imageName: require("../assets/image/version1/home-third-content-2.png"),
          title: "现场调研",
          keyword: "需求梳理 签订合同 紧急处理"
        },
        {
          imageName: require("../assets/image/version1/home-third-content-3.png"),
          title: "项目规划",
          keyword: "需求梳理 签订合同 紧急处理"
        },
        {
          imageName: require("../assets/image/version1/home-third-content-4.png"),
          title: "签订合同",
          keyword: "需求梳理 签订合同 紧急处理"
        },
        {
          imageName: require("../assets/image/version1/home-third-content-5.png"),
          title: "项目会诊",
          keyword: "需求梳理 签订合同 紧急处理"
        }
      ],
      serviceProcessRight: [
        {
          imageName: require("../assets/image/version1/home-third-content-10.png"),
          title: "项目验收",
          keyword: "需求梳理 签订合同 紧急处理"
        },
        {
          imageName: require("../assets/image/version1/home-third-content-9.png"),
          title: "跟踪服务",
          keyword: "需求梳理 签订合同 紧急处理"
        },
        {
          imageName: require("../assets/image/version1/home-third-content-8.png"),
          title: "评审指导",
          keyword: "需求梳理 签订合同 紧急处理"
        },
        {
          imageName: require("../assets/image/version1/home-third-content-7.png"),
          title: "申报项目",
          keyword: "需求梳理 签订合同 紧急处理"
        },
        {
          imageName: require("../assets/image/version1/home-third-content-6.png"),
          title: "组织材料",
          keyword: "需求梳理 签订合同 紧急处理"
        },
      ],
      startUpPeriodData: [
        {
          projectName: "郑州市新型研发机构",
          department: "郑州市科技局",
          reportDate: "8月",
          requirement: "在郑州注册且收入主要以技术为；\n主经费≥300万，且≥营收20%:办公和科研场所≥500m；\n仪器设备原值≥500万; 研发人员≥20人，占总数比例≥30%,博士学位或高级职称以上人员≥5人；\n已孵化1家以上(含)科技型企业，或上年度技术合同交易额≥营收20%；",
          policyBonus: "A类:每年是高2000万元,最多5年\nB类:每家最高500万，最多5年\nC类:首欠认定最高200万"
        },
        {
          projectName: "郑州市新型研发机构",
          department: "郑州市科技局",
          reportDate: "8月",
          requirement: "在郑州注册且收入主要以技术为；\n主经费≥300万，且≥营收20%:办公和科研场所≥500m；\n仪器设备原值≥500万; 研发人员≥20人，占总数比例≥30%,博士学位或高级职称以上人员≥5人；\n已孵化1家以上(含)科技型企业，或上年度技术合同交易额≥营收20%；",
          policyBonus: "A类:每年是高2000万元,最多5年\nB类:每家最高500万，最多5年\nC类:首欠认定最高200万"
        },
        {
          projectName: "郑州市“揭榜挂帅”专项",
          department: "郑州市科技局",
          reportDate: "8月",
          requirement: "在郑州注册且收入主要以技术为；\n主经费≥300万，且≥营收20%:办公和科研场所≥500m；\n仪器设备原值≥500万; 研发人员≥20人，占总数比例≥30%,博士学位或高级职称以上人员≥5人；\n已孵化1家以上(含)科技型企业，或上年度技术合同交易额≥营收20%；",
          policyBonus: "A类:每年是高2000万元,最多5年\nB类:每家最高500万，最多5年\nC类:首欠认定最高200万"
        },
        {
          projectName: "河南省重大科技专项",
          department: "郑州市科技局",
          reportDate: "8月",
          requirement: "在郑州注册且收入主要以技术为；\n主经费≥300万，且≥营收20%:办公和科研场所≥500m；\n仪器设备原值≥500万; 研发人员≥20人，占总数比例≥30%,博士学位或高级职称以上人员≥5人；\n已孵化1家以上(含)科技型企业，或上年度技术合同交易额≥营收20%；",
          policyBonus: "A类:每年是高2000万元,最多5年\nB类:每家最高500万，最多5年\nC类:首欠认定最高200万"
        },
        {
          projectName: "中央引导地方专项",
          department: "郑州市科技局",
          reportDate: "8月",
          requirement: "在郑州注册且收入主要以技术为；\n主经费≥300万，且≥营收20%:办公和科研场所≥500m；\n仪器设备原值≥500万; 研发人员≥20人，占总数比例≥30%,博士学位或高级职称以上人员≥5人；\n已孵化1家以上(含)科技型企业，或上年度技术合同交易额≥营收20%；",
          policyBonus: "A类:每年是高2000万元,最多5年\nB类:每家最高500万，最多5年\nC类:首欠认定最高200万"
        },

      ],
      growthPeriodData: [
        {
          projectName: "郑州市新型研发机构",
          department: "郑州市科技局",
          reportDate: "8月",
          requirement: "在郑州注册且收入主要以技术为；\n主经费≥300万，且≥营收20%:办公和科研场所≥500m；\n仪器设备原值≥500万; 研发人员≥20人，占总数比例≥30%,博士学位或高级职称以上人员≥5人；\n已孵化1家以上(含)科技型企业，或上年度技术合同交易额≥营收20%；",
          policyBonus: "A类:每年是高2000万元,最多5年\nB类:每家最高500万，最多5年\nC类:首欠认定最高200万"
        },
        {
          projectName: "郑州市新型研发机构",
          department: "郑州市科技局",
          reportDate: "8月",
          requirement: "在郑州注册且收入主要以技术为；\n主经费≥300万，且≥营收20%:办公和科研场所≥500m；\n仪器设备原值≥500万; 研发人员≥20人，占总数比例≥30%,博士学位或高级职称以上人员≥5人；\n已孵化1家以上(含)科技型企业，或上年度技术合同交易额≥营收20%；",
          policyBonus: "A类:每年是高2000万元,最多5年\nB类:每家最高500万，最多5年\nC类:首欠认定最高200万"
        },
        {
          projectName: "郑州市“揭榜挂帅”专项",
          department: "郑州市科技局",
          reportDate: "8月",
          requirement: "在郑州注册且收入主要以技术为；\n主经费≥300万，且≥营收20%:办公和科研场所≥500m；\n仪器设备原值≥500万; 研发人员≥20人，占总数比例≥30%,博士学位或高级职称以上人员≥5人；\n已孵化1家以上(含)科技型企业，或上年度技术合同交易额≥营收20%；",
          policyBonus: "A类:每年是高2000万元,最多5年\nB类:每家最高500万，最多5年\nC类:首欠认定最高200万"
        },
        {
          projectName: "河南省重大科技专项",
          department: "郑州市科技局",
          reportDate: "8月",
          requirement: "在郑州注册且收入主要以技术为；\n主经费≥300万，且≥营收20%:办公和科研场所≥500m；\n仪器设备原值≥500万; 研发人员≥20人，占总数比例≥30%,博士学位或高级职称以上人员≥5人；\n已孵化1家以上(含)科技型企业，或上年度技术合同交易额≥营收20%；",
          policyBonus: "A类:每年是高2000万元,最多5年\nB类:每家最高500万，最多5年\nC类:首欠认定最高200万"
        },
        {
          projectName: "中央引导地方专项",
          department: "郑州市科技局",
          reportDate: "8月",
          requirement: "在郑州注册且收入主要以技术为；\n主经费≥300万，且≥营收20%:办公和科研场所≥500m；\n仪器设备原值≥500万; 研发人员≥20人，占总数比例≥30%,博士学位或高级职称以上人员≥5人；\n已孵化1家以上(含)科技型企业，或上年度技术合同交易额≥营收20%；",
          policyBonus: "A类:每年是高2000万元,最多5年\nB类:每家最高500万，最多5年\nC类:首欠认定最高200万"
        },

      ],
      maturePeriodData: [
        {
          projectName: "郑州市新型研发机构",
          department: "郑州市科技局",
          reportDate: "8月",
          requirement: "在郑州注册且收入主要以技术为；\n主经费≥300万，且≥营收20%:办公和科研场所≥500m；\n仪器设备原值≥500万; 研发人员≥20人，占总数比例≥30%,博士学位或高级职称以上人员≥5人；\n已孵化1家以上(含)科技型企业，或上年度技术合同交易额≥营收20%；",
          policyBonus: "A类:每年是高2000万元,最多5年\nB类:每家最高500万，最多5年\nC类:首欠认定最高200万"
        },
        {
          projectName: "郑州市新型研发机构",
          department: "郑州市科技局",
          reportDate: "8月",
          requirement: "在郑州注册且收入主要以技术为；\n主经费≥300万，且≥营收20%:办公和科研场所≥500m；\n仪器设备原值≥500万; 研发人员≥20人，占总数比例≥30%,博士学位或高级职称以上人员≥5人；\n已孵化1家以上(含)科技型企业，或上年度技术合同交易额≥营收20%；",
          policyBonus: "A类:每年是高2000万元,最多5年\nB类:每家最高500万，最多5年\nC类:首欠认定最高200万"
        },
        {
          projectName: "郑州市“揭榜挂帅”专项",
          department: "郑州市科技局",
          reportDate: "8月",
          requirement: "在郑州注册且收入主要以技术为；\n主经费≥300万，且≥营收20%:办公和科研场所≥500m；\n仪器设备原值≥500万; 研发人员≥20人，占总数比例≥30%,博士学位或高级职称以上人员≥5人；\n已孵化1家以上(含)科技型企业，或上年度技术合同交易额≥营收20%；",
          policyBonus: "A类:每年是高2000万元,最多5年\nB类:每家最高500万，最多5年\nC类:首欠认定最高200万"
        },
        {
          projectName: "河南省重大科技专项",
          department: "郑州市科技局",
          reportDate: "8月",
          requirement: "在郑州注册且收入主要以技术为；\n主经费≥300万，且≥营收20%:办公和科研场所≥500m；\n仪器设备原值≥500万; 研发人员≥20人，占总数比例≥30%,博士学位或高级职称以上人员≥5人；\n已孵化1家以上(含)科技型企业，或上年度技术合同交易额≥营收20%；",
          policyBonus: "A类:每年是高2000万元,最多5年\nB类:每家最高500万，最多5年\nC类:首欠认定最高200万"
        },
        {
          projectName: "中央引导地方专项",
          department: "郑州市科技局",
          reportDate: "8月",
          requirement: "在郑州注册且收入主要以技术为；\n主经费≥300万，且≥营收20%:办公和科研场所≥500m；\n仪器设备原值≥500万; 研发人员≥20人，占总数比例≥30%,博士学位或高级职称以上人员≥5人；\n已孵化1家以上(含)科技型企业，或上年度技术合同交易额≥营收20%；",
          policyBonus: "A类:每年是高2000万元,最多5年\nB类:每家最高500万，最多5年\nC类:首欠认定最高200万"
        },

      ]
    }
  },
  components: {
    NavHeader,
    NavFooter
  },
  created() {
  },
  mounted() {
  },
  methods: {
    homeTableHeaderRowClassName() {
      return "home-table-header-row"
    },
    homeTableHeaderCellClassName() {
      return "home-table-header-cell"
    },
    openLeaveContact() {
      this.$prompt('', '了解一下惠岑，开启业务新发展', {
        confirmButtonText: '免费咨询',
        showCancelButton: false,
        showClose: false,
        customClass: 'leave-contact-box',
        confirmButtonClass: 'leave-contact-box-button',
        inputPlaceholder: '请输入您的手机号',
        inputPattern: /^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/,
        inputErrorMessage: '手机号格式不正确'
      }).then(({value}) => {
        this.$message({
          type: 'success',
          message: '稍后我们将联系您: ' + value
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '请留下手机号，方便我们联系您'
        });
      });
    },
  }
}
</script>

<style scoped lang="scss">
.home {
  padding: 0px;

  .home-top {
    background-image: url("../assets/image/version1/home-top.png");
    background-position: center center;
    background-size: cover;
    height: vwf(590);
    text-align: center;

    img {
      width: vwf(30);
      height: vwf(30);
      vertical-align: middle;
    }

    span {
      font-size: vwf(30);
      font-weight: 500;
      color: #333333;
      margin-left: vwf(7);
    }

    .home-top-keyword {
      display: flex;
      align-items: center;
      margin: vwf(0) vwf(20);
    }

    .home-top-title {
      font-size: vwf(60);
      font-weight: 500;
      color: #333333;
      display: flex;
      padding-left: vwf(20);
    }

    .home-top-slogan {
      font-size: vwf(48);
      font-weight: 500;
      color: #333333;
      display: flex;
      padding-left: vwf(20);
    }

    .home-top-button {
      display: flex;
      padding-left: vwf(20);

      .el-button {
        width: vwf(240);
        height: vwf(80);
        background: #006DB8;
        border: 0px solid #DCDFE6;
        color: #FFFFFF;
        font-weight: 500;
        padding: vwf(18) vwf(60);
        font-size: vwf(30);
        font-family: Microsoft YaHei-Regular, Microsoft YaHei, Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, SimSun, sans-serif;
        border-radius: vwf(40);
      }
    }

    .home-top-title-row {
      margin-top: vwf(62);
    }

    .home-top-keyword-row {
      margin-top: vwf(60);
    }

    .home-top-button-row {
      margin-top: vwf(103);
    }
  }

  .home-second {
    height: vwf(937);

    .home-second-bg {
      background-image: url("../assets/image/version1/home-second-bg.png");
      background-position: center center;
      background-size: cover;
      height: vwf(360);
      text-align: center;
    }

    .home-second-title {
      position: absolute;
      top: vwf(100);
      width: 100%;
    }

    .home-second-content {
      position: absolute;
      top: vwf(180);
      width: 100%;

      .el-collapse {
        background: #FFFFFF;
        box-shadow: vwf(0) vwf(10) vwf(20) vwf(1) rgba(0, 0, 0, 0.16);
        border-radius: vwf(20) vwf(20) vwf(20) vwf(20);
        border: vwf(0);
        padding: vwf(30) vwf(38) vwf(30) vwf(38);
      }

      /deep/ .el-collapse-item__header {
        font-size: vwf(24);
        color: #333333;
        border-bottom: 0px;
        height: vwf(61);
        line-height: vwf(61);
      }

      .el-collapse-item-title {
        width: vwf(1234);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      /deep/ .el-collapse-item__content {
        p {
          padding-bottom: vwf(20);
          font-size: vwf(18);
          color: #666666;
          line-height: vwf(28);
        }

        span {
          padding-bottom: vwf(17);
          font-size: vwf(16);
          color: #999999;
          line-height: vwf(28);
        }
      }

      /deep/ .el-collapse-item__wrap {
        border-bottom: 0px;
      }
    }

    .home-second-content-button {
      display: flex;
      align-items: center;
      margin-top: vwf(50);

      .el-button {
        width: vwf(120);
        height: vwf(40);
        margin: 0 auto;
        background: #006DB8;
        border: 0px solid #DCDFE6;
        color: #FFFFFF;
        font-weight: 500;
        padding: vwf(8) vwf(28);
        font-size: vwf(16);
        font-family: Microsoft YaHei-Regular, Microsoft YaHei, Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, SimSun, sans-serif;
        border-radius: vwf(20);
      }
    }
  }

  .home-third {
    height: vwf(1000);
    background: #F7FAFD;

    .home-third-title {
      margin-top: vwf(100);
    }

    .home-third-content {
      margin-top: vwf(70);

      .home-third-content-row {
        width: 90%;
        height: vwf(116);
      }

      .home-third-content-row:hover {
        background: #006DB8;
        box-shadow: vwf(0) vwf(3) vwf(6) vwf(1) rgba(10, 65, 255, 0.16);
        border-radius: vwf(6) vwf(6) vwf(6) vwf(6);

        .home-third-content-title {
          color: #FFFFFF;
        }

        .home-third-content-keyword {
          color: #A1B6FF;
        }

        .home-third-content-no {
          filter: brightness(100);
        }

        .home-third-content-arrow {
          width: vwf(32);
          height: vwf(32);
          content: url("../assets/image/version1/home-third-content-arrow-s.png");
        }
      }

      .home-third-content-row-right {
        width: 90%;
        height: vwf(116);
        margin-left: 10%;
      }

      .home-third-content-row-right:hover {
        background: #006DB8;
        box-shadow: vwf(0) vwf(3) vwf(6) vwf(1) rgba(10, 65, 255, 0.16);
        border-radius: vwf(6) vwf(6) vwf(6) vwf(6);

        .home-third-content-title {
          color: #FFFFFF;
        }

        .home-third-content-keyword {
          color: #A1B6FF;
        }

        .home-third-content-no {
          filter: brightness(100);
        }

        .home-third-content-arrow-right {
          width: vwf(32);
          height: vwf(32);
          content: url("../assets/image/version1/home-third-content-arrow-s.png");
        }
      }

      .home-third-content-no {
        width: vwf(54);
        height: vwf(32);
      }

      .home-third-content-title {
        font-size: vwf(20);
        font-weight: blod;
        color: #333333;
      }

      .home-third-content-keyword {
        font-size: vwf(16);
        color: #BABECD;
      }

      .home-third-content-arrow {
        width: vwf(18);
        height: vwf(10);
        margin-left: vwf(40);
      }

      .home-third-content-arrow-right {
        width: vwf(18);
        height: vwf(10);
        margin-right: vwf(40);
        transform: rotate(180deg);
      }

      .home-third-content-m {
        width: vwf(468);
        height: vwf(580);
      }

    }
  }

  .home-fourth {
    .home-fourth-title {
      margin-top: vwf(100);
    }

    .home-fourth-content {
      margin-top: vwf(50);
    }
  }

  .home-fifth {
    .home-fifth-title {
      margin-top: vwf(100);
    }

    .home-fifth-content {
      margin-top: vwf(50);
    }
  }

  .home-sixth {
    margin-bottom: vwf(100);

    .home-sixth-title {
      margin-top: vwf(100);
    }

    .home-sixth-content {
      margin-top: vwf(50);
    }
  }

  .home-button-icon {
    width: vwf(12);
    height: vwf(12);
    vertical-align: middle;
    margin-left: vwf(6);
  }

  .home-look-all-button {
    .el-button {
      width: vwf(120);
      height: vwf(40);
      margin: 0 auto;
      background: #006DB8;
      border: 0px solid #DCDFE6;
      color: #FFFFFF;
      font-weight: 500;
      padding: vwf(8) vwf(19);
      font-size: vwf(16);
      font-family: Microsoft YaHei-Regular, Microsoft YaHei, Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, SimSun, sans-serif;
      border-radius: vwf(4);
    }
  }

  /deep/ .home-table-header-row {
    background-image: url(../assets/image/version1/home-table-header-bg.png);
    background-position: center center;
    background-size: cover;
  }

  /deep/ .home-table-header-cell {
    background-color: transparent;
  }


  .home-little-title {
    font-size: vwf(30);
    font-weight: bold;
    color: #333333;
  }

  .home-little-icon {
    width: vwf(24);
    height: vwf(24);
    vertical-align: middle;
  }

  // 覆盖elementUI
  .el-table {
    font-size: vwf(16);
    color: #333333;

    /deep/ .cell {
      line-height: 1.2 !important;
      white-space: pre-wrap;
    }

    /deep/ tbody tr:hover > td {
      background-color: #FFFFFF;
    }
  }

  /deep/ .el-table thead {
    color: #333333;
    font-weight: 400;
    font-size: vwf(20);
  }

  /deep/ .el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
    background: #F7F7F7;
  }

  /deep/ .el-table td.el-table__cell, .el-table th.el-table__cell.is-leaf {
    border-bottom: 0px;
  }

  // 不起作用
  // /deep/ .leave-contact-box {
  //   width: vwf(420);
  //   padding-bottom: vwf(10);
  //   border-radius: vwf(4);
  //   border: vwf(1) solid #EBEEF5;
  //   font-size: vwf(18);
  //   box-shadow: 0 vwf(2) vwf(12) 0 rgba(0,0,0,.1);
  // }

}
</style>
